import { createAction, actionTypeCreator, read } from 'relient/actions';

const actionType = actionTypeCreator(__filename);

export const READ_AGENCY_SUMMARY = actionType('READ_AGENCY_SUMMARY');
export const READ_CONTRACT_SUMMARY = actionType('READ_CONTRACT_SUMMARY');

export const readAgencySummary = createAction(
  READ_AGENCY_SUMMARY,
  () => read('/agency/action/summarize'),
);

export const readContractSummary = createAction(
  READ_CONTRACT_SUMMARY,
  ({
    startAt,
    endAt,
  } = {}) => read('/contract/action/summarize', {
    startAt,
    endAt,
  }),
);
