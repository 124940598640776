import { handleActions, combineActions, merge } from 'relient/reducers';
import { recharge } from '../schema';
import {
  CREATE,
  READ_ONE,
  READ_MINE,
  READ_ALL,
} from '../actions/recharge';

export default {
  recharge: handleActions({
    [combineActions(
      CREATE,
      READ_ONE,
    )]: merge({ schema: recharge }),

    [combineActions(
      READ_MINE,
      READ_ALL,
    )]: merge({ schema: recharge, dataKey: 'content' }),

  }, {}),
};
