import { handleActions, merge, combineActions } from 'relient/reducers';
import { prop } from 'lodash/fp';
import { wallet } from '../schema';
import { READ_ONE, READ_ALL, READ_MINE } from '../actions/wallet';
import {
  CREATE as CREATE_WITHDRAWAL,
} from '../actions/withdrawal';
import {
  CREATE as CREATE_RECHARGE,
} from '../actions/recharge';

export default {
  wallet: handleActions({
    [combineActions(READ_ONE, READ_MINE)]: merge({ schema: wallet }),

    [READ_ALL]: merge({ schema: wallet, dataKey: 'content' }),

    [CREATE_WITHDRAWAL]: (originalWallet, { payload: { walletId, amount } }) => ({
      ...originalWallet,
      [walletId]: {
        ...prop(walletId)(originalWallet),
        balance: prop(`${walletId}.balance`)(originalWallet) - amount,
      },
    }),

    [CREATE_RECHARGE]: (originalWallet, { payload: { walletId, amount } }) => ({
      ...originalWallet,
      [walletId]: {
        ...prop(walletId)(originalWallet),
        balance: prop(`${walletId}.balance`)(originalWallet) + amount,
      },
    }),
  }, {}),
};
