import { handleActions, combineActions } from 'relient/reducers';
import {
  READ_ONE,
  UPDATE,
} from '../actions/misc';

export default {
  misc: handleActions({
    [combineActions(
      READ_ONE,
      UPDATE,
    )]: (misc, {
      payload,
    }) => (payload || {}),
  }, {}),
};
