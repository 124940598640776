import { DEFAULT_SIZE } from 'shared/constants/pagination';
import { createAction, actionTypeCreator, read } from 'relient/actions';

const actionType = actionTypeCreator(__filename);

export const READ_ALL = actionType('READ_ALL');
export const READ_MINE = actionType('READ_MINE');

export const readAll = createAction(
  READ_ALL, ({
    walletIds,
    type,
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/wallet-history/all', { walletIds, type, page, size, sort }),
);

export const readMine = createAction(
  READ_MINE, ({
    cryptocurrencyId,
    type,
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/wallet-history/mine', { cryptocurrencyId, type, page, size, sort }),
);
