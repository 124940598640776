import { merge, handleActions } from 'relient/reducers';
import { transaction } from '../schema';
import {
  READ_ALL,
} from '../actions/transaction';

export default {
  transaction: handleActions({
    [READ_ALL]: merge({ schema: transaction, dataKey: 'content' }),
  }, {}),
};
