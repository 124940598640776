import { handleActions } from 'relient/reducers';
import {
  READ_CRYPTOCURRENCY,
  READ_OVERALL,
  READ_THIRD_PARTY_AGENCY,
} from '../actions/statistics';

export default {
  statistics: handleActions({

    [READ_CRYPTOCURRENCY]: (statistics, { payload }) => ({
      ...statistics,
      cryptocurrency: payload,
    }),

    [READ_OVERALL]: (statistics, { payload }) => ({
      ...statistics,
      overall: payload,
    }),

    [READ_THIRD_PARTY_AGENCY]: (statistics, { payload }) => ({
      ...statistics,
      thirdPartyAgency: payload,
    }),

  }, { cryptocurrency: [], overall: {} }),
};
