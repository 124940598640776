import { handleActions, combineActions, merge } from 'relient/reducers';
import { agencyWithdrawal } from '../schema';
import {
  CREATE,
  READ_ONE,
  READ_MINE,
  READ_ALL,
  UPDATE,
} from '../actions/agency-withdrawal';

export default {
  agencyWithdrawal: handleActions({
    [combineActions(
      CREATE,
      READ_ONE,
      UPDATE,
    )]: merge({ schema: agencyWithdrawal }),

    [combineActions(
      READ_MINE,
      READ_ALL,
    )]: merge({ schema: agencyWithdrawal, dataKey: 'content' }),

  }, {}),
};
