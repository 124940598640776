import { createAction, actionTypeCreator, read } from 'relient/actions';
import { DEFAULT_SIZE } from '../constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ALL = actionType('READ_ALL');
export const CREATE = actionType('CREATE');

export const readAll = createAction(
  READ_ALL,
  ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/agency-sales-commission/all', { page, size, sort }),
);
