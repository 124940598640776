import { createAction, actionTypeCreator, post, read, put, del } from 'relient/actions';
import { ALIPAY } from 'shared/constants/payment-method';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ONE = actionType('READ_ONE');
export const READ_MINE = actionType('READ_MINE');
export const READ_ALL = actionType('READ_ALL');
export const READ_MY_INVITED = actionType('READ_MY_INVITED');
export const CREATE = actionType('CREATE');
export const CREATE_TEMPORARY = actionType('CREATE_TEMPORARY');
export const CREATE_PAYMENT = actionType('CREATE_PAYMENT');
export const UPDATE = actionType('UPDATE');
export const REMOVE = actionType('REMOVE');
export const NOTIFY = actionType('NOTIFY');

export const readMine = createAction(
  READ_MINE, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/order/mine', { page, size, sort }),
);

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/order/${id}`),
);

export const readAll = createAction(
  READ_ALL, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/order/all', { page, size, sort }),
);

export const create = createAction(
  CREATE, ({
    accountId,
    items,
    paymentMethod,
    currency,
    online = true,
    couponCode,
  }) => post('/order', {
    accountId,
    items,
    paymentMethod,
    currency,
    online,
    couponCode,
  }),
);

export const createTemporary = createAction(
  CREATE_TEMPORARY, ({
    items,
    paymentMethod,
    currency,
    online = true,
    couponCode,
  }) => post('/order/temporary', {
    items,
    paymentMethod,
    currency,
    online,
    couponCode,
  }),
);

export const createPayment = createAction(
  CREATE_PAYMENT, ({
    id,
    currency,
    method = ALIPAY,
  }) => post(`/order/${id}/payment`, { method, currency }),
);

export const update = createAction(
  UPDATE, ({
    id,
    items,
    status,
  }) => put(`/order/${id}`, {
    id,
    items,
    status,
  }),
);

export const remove = createAction(REMOVE, ({ id }) => del(`/order/${id}`));

export const notify = createAction(
  NOTIFY,
  ({ paymentId, payerId }) => post('/notify/paypal-payment', { paymentId, payerId }),
);

export const readMyInvited = createAction(
  READ_MY_INVITED, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/order/invited', { page, size, sort }),
);
