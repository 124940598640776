import { createAction, actionTypeCreator, post, read, put } from 'relient/actions';
import { toNumber } from 'lodash/fp';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const CREATE = actionType('CREATE');
export const READ_ONE = actionType('READ_ONE');
export const READ_MINE = actionType('READ_MINE');
export const READ_ALL = actionType('READ_ALL');
export const UPDATE = actionType('UPDATE');

export const create = createAction(
  CREATE, ({
    agencyId,
    bankName,
    bankAccount,
    amount,
    currencyCode,
    bankAccountOwner,
  }) => post('/agency-withdrawal', {
    agencyId,
    bankName,
    bankAccount,
    amount: toNumber(amount),
    currencyCode,
    bankAccountOwner,
  }),
);

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/agency-withdrawal/${id}`),
);

export const readMine = createAction(
  READ_MINE, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/agency-withdrawal/mine', { page, size, sort }),
);

export const readAll = createAction(
  READ_ALL, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/agency-withdrawal/all', { page, size, sort }),
);

export const update = createAction(
  UPDATE, ({
    id,
    status,
  }) => put(`/agency-withdrawal/${id}`, {
    status,
  }),
);
