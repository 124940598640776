import { createAction, actionTypeCreator, read, put } from 'relient/actions';

const actionType = actionTypeCreator(__filename);

export const READ_ONE = actionType('READ_ONE');
export const UPDATE = actionType('UPDATE');

export const readOne = createAction(
  READ_ONE,
  () => read('/system-setting/withdrawal-notification'),
);

export const update = createAction(
  UPDATE, ({
    emails,
    phone,
  }) => put('/system-setting/withdrawal-notification', {
    emails,
    phone,
  }),
);
