import { handleActions, combineActions, merge } from 'relient/reducers';
import { earning } from '../schema';
import { READ_MINE, READ_ALL } from '../actions/earning';

export default {
  earning: handleActions({
    [combineActions(
      READ_MINE,
      READ_ALL,
    )]: merge({ schema: earning, dataKey: 'content' }),
  }, {}),
};
