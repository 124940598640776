import { createAction, actionTypeCreator, post, read } from 'relient/actions';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const CREATE = actionType('CREATE');
export const READ_ONE = actionType('READ_ONE');
export const READ_MINE = actionType('READ_MINE');
export const READ_ALL = actionType('READ_ALL');

export const create = createAction(
  CREATE, ({
    walletId,
    transactionHash,
  }) => post('/recharge', {
    walletId,
    transactionHash,
  }),
);

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/recharge/${id}`),
);

export const readMine = createAction(
  READ_MINE, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/recharge/mine', { page, size, sort }),
);

export const readAll = createAction(
  READ_ALL, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/recharge/all', { page, size, sort }),
);
