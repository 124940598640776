import { handleActions, merge, combineActions } from 'relient/reducers';
import {
  READ_ALL,
} from '../actions/sales-commission';
import { salesCommission } from '../schema';

export default {
  salesCommission: handleActions({
    [combineActions(READ_ALL)]: merge({ schema: salesCommission, dataKey: 'content' }),

  }, {}),
};
