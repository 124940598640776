import { createAction, actionTypeCreator, post, read, put } from 'relient/actions';
import { toNumber } from 'lodash/fp';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const CREATE = actionType('CREATE');
export const READ_ONE = actionType('READ_ONE');
export const READ_MINE = actionType('READ_MINE');
export const READ_ALL = actionType('READ_ALL');
export const UPDATE = actionType('UPDATE');
export const SEND_VERIFICATION_CODE = actionType('SEND_VERIFICATION_CODE');

export const create = createAction(
  CREATE, ({
    walletId,
    address,
    amount,
    verificationCode,
  }) => post('/withdrawal', {
    walletId,
    address,
    amount: toNumber(amount),
    verificationCode,
  }),
);

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/withdrawal/${id}`),
);

export const readMine = createAction(
  READ_MINE, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/withdrawal/mine', { page, size, sort }),
);

export const readAll = createAction(
  READ_ALL, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/withdrawal/all', { page, size, sort }),
);

export const update = createAction(
  UPDATE, ({
    id,
    status,
    transactionHash,
    comment,
  }) => put(`/withdrawal/${id}`, {
    status,
    transactionHash,
    comment,
  }),
);

export const sendVerificationCode = createAction(
  SEND_VERIFICATION_CODE,
  ({ captcha }) => post('/withdrawal/verification-code', { captcha }),
);
