import { createAction, actionTypeCreator, post, read, put, del } from 'relient/actions';
import { toNumber } from 'lodash/fp';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ONE = actionType('READ_ONE');
export const READ_MINE = actionType('READ_MINE');
export const READ_ALL = actionType('READ_ALL');
export const CREATE = actionType('CREATE');
export const UPDATE = actionType('UPDATE');
export const REMOVE = actionType('REMOVE');

export const readMine = createAction(
  READ_MINE,
  ({ page = 0, size = DEFAULT_SIZE } = {}) => read('/contract/mine', { page, size }),
);

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/contract/${id}`),
);

export const readAll = createAction(
  READ_ALL,
  ({
    channel,
    page = 0,
    size = DEFAULT_SIZE,
  } = {}) => read('/contract/all', {
    channel,
    page,
    size,
  }),
);

export const create = createAction(
  CREATE, ({
    type,
    power,
    platformFeeRate,
    unit,
    inventory,
    capacity,
    cryptocurrencyId,
    electricity = 0,
    status,
    costPriceUSD,
    sellingPriceUSD,
    channels,
    daysOfExpiration,
    newbie,
    method,
    fixedEarningUsd,
    multilevelAgencyEntry,
    agencyCommissionRate,
    agencyCommissionLevel2Rate,
    agencyFeeLevel2Rate,
    earningEnabled,
  }) => post('/contract', {
    type,
    power: toNumber(power),
    platformFeeRate: toNumber(platformFeeRate),
    unit: toNumber(unit),
    costPriceUSD: toNumber(costPriceUSD),
    sellingPriceUSD: toNumber(sellingPriceUSD),
    inventory: toNumber(inventory),
    capacity: toNumber(capacity),
    cryptocurrencyId,
    electricity: toNumber(electricity),
    status,
    channels,
    daysOfExpiration,
    newbie,
    method,
    fixedEarningUsd,
    multilevelAgencyEntry,
    agencyCommissionRate,
    agencyCommissionLevel2Rate,
    agencyFeeLevel2Rate,
    earningEnabled,
  }),
);

export const update = createAction(
  UPDATE, ({
    id,
    type,
    power,
    platformFeeRate,
    unit,
    inventory,
    capacity,
    cryptocurrencyId,
    electricity,
    status,
    channels,
    daysOfExpiration,
    newbie,
    earningEnabled,
  }) => put(`/contract/${id}`, {
    type,
    power: toNumber(power),
    platformFeeRate: toNumber(platformFeeRate),
    unit: toNumber(unit),
    inventory: toNumber(inventory),
    capacity: toNumber(capacity),
    cryptocurrencyId,
    electricity: toNumber(electricity),
    status,
    channels,
    daysOfExpiration,
    newbie,
    earningEnabled,
  }),
);

export const remove = createAction(REMOVE, ({ id }) => del(`/contract/${id}`));
