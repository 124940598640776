import { handleActions, merge, combineActions } from 'relient/reducers';
import {
  READ_ALL,
  CREATE,
} from '../actions/withdrawal-record';
import { withdrawalRecord } from '../schema';

export default {
  withdrawalRecord: handleActions({
    [combineActions(READ_ALL, CREATE)]: merge({ schema: withdrawalRecord }),

  }, {}),
};
