import { post, read, put, del, createAction, actionTypeCreator } from 'relient/actions';
import { PERCENTAGE } from 'shared/constants/coupon-type';

const actionType = actionTypeCreator(__filename);

export const CREATE = actionType('CREATE');
export const UPDATE = actionType('UPDATE');
export const READ_ALL = actionType('READ_ALL');
export const REMOVE = actionType('REMOVE');

export const create = createAction(
  CREATE, ({
    type = PERCENTAGE,
    name,
    code,
    amount,
    capacity,
    inventory,
    status,
  }) => post(
    '/discount', {
      type,
      name,
      code,
      amount,
      capacity,
      inventory,
      status,
    },
  ),
);

export const update = createAction(
  UPDATE, ({
    id,
    name,
    type,
    amount,
    inventory,
    status,
  }) => put(
    `/discount/${id}`, {
      name,
      type,
      amount,
      inventory,
      status,
    },
  ),
);

export const readAll = createAction(
  READ_ALL, () => read('/discount/all'),
);

export const remove = createAction(
  REMOVE, ({ id }) => del(`/discount/${id}`),
);
