import { createAction, actionTypeCreator, post, read, put, del } from 'relient/actions';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

const getActivationUrlTemplate = (origin) => `${origin}/activation?accountId=\${accountId}&code=\${code}`;

export const REGISTER = actionType('REGISTER');
export const READ_MINE = actionType('READ_MINE');
export const READ_ONE = actionType('READ_ONE');
export const READ_ALL = actionType('READ_ALL');
export const CREATE = actionType('CREATE');
export const UPDATE = actionType('UPDATE');
export const REMOVE = actionType('REMOVE');
export const CHANGE_PASSWORD = actionType('CHANGE_PASSWORD');
export const CHANGE_PASSWORD_WITHOUT_ACCOUNT_ID = actionType('CHANGE_PASSWORD_WITHOUT_ACCOUNT_ID');
export const REQUEST_CHANGE_PASSWORD = actionType('REQUEST_CHANGE_PASSWORD');
export const REQUEST_ACTIVATE_CODE = actionType('REQUEST_ACTIVATE_CODE');

export const register = createAction(
  REGISTER, ({
    username,
    password,
    captcha,
    invitationCode,
    inviteCode,
    agencyId,
    origin,
    language,
    agencyInvitationCode,
  }) => post('/account/action/register', {
    username,
    email: username,
    password,
    captcha,
    activationUrlTemplate: getActivationUrlTemplate(origin),
    invitationCode,
    inviteCode,
    agencyId,
    language,
    agencyInvitationCode,
  }),
  () => ({ withoutAuth: true, ignoreAuthRedirection: true }),
);

export const readMine = createAction(
  READ_MINE,
  () => read('/account/mine'),
);

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/account/${id}`),
);

export const readAll = createAction(
  READ_ALL,
  ({ page = 0, size = DEFAULT_SIZE } = {}) => read('/account/all', { page, size }),
);

export const create = createAction(
  CREATE, ({
    username,
    password,
    status,
    avatar,
    agencyId,
  }) => post('/account', {
    email: username,
    username,
    password,
    status,
    avatar,
    agencyId,
  }),
);

export const update = createAction(
  UPDATE, ({
    id,
    username,
    password,
    status,
    avatar,
    language,
  }) => put(`/account/${id}`, {
    username,
    password,
    status,
    avatar,
    language,
  }),
);

export const remove = createAction(REMOVE, ({ id }) => del(`/account/${id}`));

export const changePassword = createAction(
  CHANGE_PASSWORD,
  ({ id, oldPassword, newPassword }) => post(`/account/${id}/action/reset-password`, { oldPassword, newPassword }),
);

export const requestChangePassword = createAction(
  REQUEST_CHANGE_PASSWORD,
  ({ email, captcha }) => post('/account/action/request-reset-password', { email, captcha }),
);

export const changePasswordWithoutAccountId = createAction(
  CHANGE_PASSWORD_WITHOUT_ACCOUNT_ID,
  ({ email, code, newPassword }) => post('/account/action/reset-password', { email, code, newPassword }),
);

export const requestActivateCode = createAction(
  REQUEST_ACTIVATE_CODE,
  ({ email, captcha, origin }) => post('/account/action/request-activate-code', {
    email,
    activationUrlTemplate: getActivationUrlTemplate(origin),
    captcha,
  }),
);
