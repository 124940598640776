import { createAction, actionTypeCreator, read } from 'relient/actions';
import moment from 'moment';

const actionType = actionTypeCreator(__filename);

export const READ_CRYPTOCURRENCY = actionType('READ_CRYPTOCURRENCY');
export const READ_OVERALL = actionType('READ_OVERALL');
export const READ_THIRD_PARTY_AGENCY = actionType('READ_THIRD_PARTY_AGENCY');

export const readCryptocurrency = createAction(
  READ_CRYPTOCURRENCY,
  () => read('/statistic/cryptocurrency'),
);

export const readOverall = createAction(
  READ_OVERALL,
  ({ startAt, endAt } = {}) => read('/statistic/overall', {
    startAt: startAt && moment(startAt).format('YYYY-MM-DD'),
    endAt: endAt && moment(endAt).add(1, 'day').format('YYYY-MM-DD'),
  }),
);

export const readThirdPartyAgency = createAction(
  READ_THIRD_PARTY_AGENCY,
  () => read('/statistic/third-party-agency'),
);
