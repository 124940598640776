import { schema } from 'relient/reducers';

export const account = new schema.Entity('account');
export const contract = new schema.Entity('contract');
export const cryptocurrency = new schema.Entity('cryptocurrency');
export const wallet = new schema.Entity('wallet');
export const withdrawal = new schema.Entity('withdrawal');
export const agencyWithdrawal = new schema.Entity('agencyWithdrawal');
export const recharge = new schema.Entity('recharge');
export const log = new schema.Entity('log');
export const earning = new schema.Entity('earning');
export const orderItem = new schema.Entity('orderItem');
export const order = new schema.Entity('order', { items: [orderItem] });
export const agencyContract = new schema.Entity('agencyContract');
export const agency = new schema.Entity('agency');
export const walletHistory = new schema.Entity('walletHistory');
export const role = new schema.Entity('role');
export const accountContract = new schema.Entity('accountContract');
export const accountPayment = new schema.Entity('accountPayment');
export const thirdPartyRecharge = new schema.Entity('thirdPartyRecharge');
export const thirdPartyWithdrawal = new schema.Entity('thirdPartyWithdrawal');
export const thirdPartyApplication = new schema.Entity('thirdPartyApplication');
export const withdrawalRecord = new schema.Entity('withdrawalRecord');
export const coupon = new schema.Entity('coupon');
export const news = new schema.Entity('news');
export const transaction = new schema.Entity('transaction');
export const salesCommission = new schema.Entity('salesCommission');
