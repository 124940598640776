import { handleActions } from 'relient/reducers';
import {
  READ_AGENCY_SUMMARY,
  READ_CONTRACT_SUMMARY,
} from '../actions/summary';

export default {
  summary: handleActions({
    [READ_AGENCY_SUMMARY]: (originalSummary, { payload }) => ({
      ...originalSummary,
      agency: payload,
    }),

    [READ_CONTRACT_SUMMARY]: (originalSummary, { payload }) => ({
      ...originalSummary,
      contract: payload,
    }),

  }, {
    agency: null,
    contract: null,
  }),
};
