import { actionTypeCreator, createAction } from 'relient/actions';

const actionType = actionTypeCreator(__filename);

export const SET_FEATURE_GROUP = actionType('SET_FEATURE_GROUP');
export const setFeatureGroup = createAction(SET_FEATURE_GROUP);

export const SET_FEATURE = actionType('SET_FEATURE');
export const setFeature = createAction(SET_FEATURE);

export const SET_USER_AGENT = actionType('SET_USER_AGENT');
export const setUserAgent = createAction(SET_USER_AGENT);

export const TOGGLE_LOGIN_MODAL = actionType('TOGGLE_LOGIN_MODAL');
export const toggleLoginModal = createAction(TOGGLE_LOGIN_MODAL);

export const TOGGLE_REGISTER_MODAL = actionType('TOGGLE_REGISTER_MODAL');
export const toggleRegisterModal = createAction(TOGGLE_REGISTER_MODAL);

export const TOGGLE_FORGET_PASSWORD_MODAL = actionType('TOGGLE_FORGET_PASSWORD_MODAL');
export const toggleForgetPasswordModal = createAction(TOGGLE_FORGET_PASSWORD_MODAL);

export const TOGGLE_RESET_PASSWORD_MODAL = actionType('TOGGLE_RESET_PASSWORD_MODAL');
export const toggleResetPasswordModal = createAction(TOGGLE_RESET_PASSWORD_MODAL);

export const SET_HOST = actionType('SET_HOST');
export const setHost = createAction(SET_HOST);
