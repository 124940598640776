import { createAction, actionTypeCreator, read, post } from 'relient/actions';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ALL = actionType('READ_ALL');
export const WITHDRAW = actionType('WITHDRAW');

export const readAll = createAction(
  READ_ALL, ({
    page = 0,
    size = DEFAULT_SIZE,
    cryptocurrencyId,
    type,
    status,
  } = {}) => read('/bitcoin/transaction/all', {
    page,
    size,
    cryptocurrencyId,
    type,
    status,
  }),
);

export const withdraw = createAction(
  WITHDRAW,
  ({ amount }) => post('/bitcoin/action/withdraw', { amount }),
);
