import { createStore, applyMiddleware, compose } from 'redux';
import {
  history as historyMiddleware,
  serverError,
} from 'relient/middlewares';
import { push } from 'relient/actions/history';
import { logout } from 'shared/actions/auth';
import reducers from 'shared/reducers';
import fetch from 'isomorphic-fetch/fetch-npm-browserify';
import fetchMiddleware from 'shared/middlewares/fetch';
import { error } from 'shared/components/message';
import { prop, flow, first } from 'lodash/fp';
import authorization from './middlewares/authorization';
import history from './history';
import pushMiddleware from './middlewares/push';

const { __REDUX_DEVTOOLS_EXTENSION__, __INITIAL_STATE__ = {} } = global;

const middlewares = [
  fetchMiddleware({ fetch, apiDomain: `${global.location.origin}/api` }),
  authorization,
  pushMiddleware,
  historyMiddleware(history),
  serverError({
    onUnauthorized: ({ dispatch }) => {
      dispatch(logout());
      dispatch(push('/'));
    },
    onGlobalWarning: async ({ payload }) => {
      if (payload instanceof Array) {
        error(flow(first, prop('message'))(payload), 5);
      } else {
        error(prop('message')(payload) || prop('error')(payload), 5);
      }
    },
  }),
];
let enhancer = applyMiddleware(...middlewares);

if (__DEV__) {
  // eslint-disable-next-line global-require
  middlewares.push(require('redux-logger').createLogger({ collapsed: true }));
  if (__REDUX_DEVTOOLS_EXTENSION__) {
    enhancer = compose(
      applyMiddleware(...middlewares),
      __REDUX_DEVTOOLS_EXTENSION__(),
    );
  }
}

export default createStore(
  reducers,
  __INITIAL_STATE__,
  enhancer,
);
