import { merge, remove, handleActions, combineActions } from 'relient/reducers';
import { account } from '../schema';
import { LOGIN } from '../actions/auth';
import {
  REGISTER,
  READ_MINE,
  UPDATE,
  READ_ALL,
  READ_ONE,
  CREATE,
  REMOVE,
} from '../actions/account';

export default {
  account: handleActions({
    [combineActions(
      REGISTER,
      READ_MINE,
      READ_ONE,
      UPDATE,
      CREATE,
    )]: merge({ schema: account }),

    [READ_ALL]: merge({ schema: account, dataKey: 'content' }),

    [combineActions(
      LOGIN,
    )]: merge({ schema: account, dataKey: 'account' }),

    [REMOVE]: remove(account),

  }, {}),
};
