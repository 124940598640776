import { handleActions, combineActions, merge, remove } from 'relient/reducers';
import { cryptocurrency } from '../schema';
import {
  UPDATE,
  READ_ALL,
  CREATE,
  READ_ONE,
  REMOVE,
} from '../actions/cryptocurrency';

export default {
  cryptocurrency: handleActions({
    [combineActions(
      CREATE,
      READ_ONE,
      UPDATE,
      CREATE,
    )]: merge({ schema: cryptocurrency }),

    [READ_ALL]: merge({ schema: cryptocurrency }),

    [REMOVE]: remove(cryptocurrency),

  }, {}),
};
