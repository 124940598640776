import { merge, remove, handleActions, combineActions } from 'relient/reducers';
import { contract } from '../schema';
import {
  READ_MINE,
  UPDATE,
  READ_ALL,
  READ_ONE,
  CREATE,
  REMOVE,
} from '../actions/contract';

export default {
  contract: handleActions({
    [combineActions(
      READ_ONE,
      UPDATE,
      CREATE,
    )]: merge({ schema: contract }),

    [combineActions(READ_ALL, READ_MINE)]: merge({ schema: contract, dataKey: 'content' }),

    [REMOVE]: remove(contract),

  }, {}),
};
