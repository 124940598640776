import { merge, remove, handleActions, combineActions } from 'relient/reducers';
import { order } from '../schema';
import {
  READ_MINE,
  UPDATE,
  READ_ALL,
  READ_ONE,
  CREATE,
  REMOVE,
  READ_MY_INVITED,
} from '../actions/order';

export default {
  order: handleActions({
    [combineActions(
      READ_ONE,
      UPDATE,
      CREATE,
    )]: merge({ schema: order }),

    [combineActions(READ_ALL, READ_MINE, READ_MY_INVITED)]: merge({ schema: order, dataKey: 'content' }),

    [REMOVE]: remove(order),

  }, {}),

  orderItem: handleActions({
    [combineActions(
      READ_ONE,
      CREATE,
      UPDATE,
    )]: merge({ schema: order, entityKey: 'orderItem' }),

    [combineActions(
      READ_ALL,
      READ_MINE,
      READ_MY_INVITED,
    )]: merge({ schema: order, dataKey: 'content', entityKey: 'orderItem' }),
  }, {}),
};
