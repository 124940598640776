import { merge, handleActions } from 'relient/reducers';
import { agencyContract } from '../schema';
import {
  UPDATE,
  READ_ALL,
} from '../actions/agency-contract';

export default {
  agencyContract: handleActions({
    [UPDATE]: merge({ schema: agencyContract }),

    [READ_ALL]: merge({ schema: agencyContract, dataKey: 'content' }),

  }, {}),
};
