import { handleActions, combineActions, merge } from 'relient/reducers';
import { walletHistory } from '../schema';
import { READ_ALL, READ_MINE } from '../actions/wallet-history';

export default {
  walletHistory: handleActions({
    [combineActions(
      READ_ALL,
      READ_MINE,
    )]: merge({ schema: walletHistory, dataKey: 'content' }),
  }, {}),
};
