import { createAction, actionTypeCreator, read, post } from 'relient/actions';
import { toNumber } from 'lodash/fp';

const actionType = actionTypeCreator(__filename);

export const READ_BY_AGENCY = actionType('READ_BY_AGENCY');
export const CREATE = actionType('CREATE');

export const readByAgency = createAction(
  READ_BY_AGENCY,
  ({ agencyId }) => read(`/agency/${agencyId}/payments/all`),
);

export const create = createAction(
  CREATE, ({
    agencyId,
    amount,
    type,
    issuedAt,
    startAt,
    endAt,
  }) => post(`/agency/${agencyId}/action/place-payment`, {
    agencyId,
    amount: toNumber(amount),
    type,
    issuedAt,
    startAt,
    endAt,
  }),
);
