import { createAction, actionTypeCreator, post, read, put, del } from 'relient/actions';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ALL = actionType('READ_ALL');
export const CREATE = actionType('CREATE');
export const READ_ONE = actionType('READ_ONE');
export const UPDATE = actionType('UPDATE');
export const REMOVE = actionType('REMOVE');

export const readAll = createAction(
  READ_ALL,
  ({ page = 0, size = DEFAULT_SIZE } = {}) => read('/cryptocurrency/all', { page, size }),
);

export const create = createAction(
  CREATE, ({
    shortName,
    name,
    nameCN,
    blockReward,
    address,
  }) => post('/cryptocurrency', {
    name,
    shortName,
    nameCN,
    blockReward,
    address,
  }),
);

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/cryptocurrency/${id}`),
);

export const update = createAction(
  UPDATE, ({
    id,
    name,
    shortName,
    nameCN,
    blockReward,
    address,
  }) => put(`/cryptocurrency/${id}`, {
    name,
    shortName,
    nameCN,
    blockReward,
    address,
  }),
);

export const remove = createAction(
  REMOVE,
  ({ id }) => del(`/cryptocurrency/${id}`),
);
