import { handleActions, combineActions } from 'relient/reducers';
import {
  READ,
  UPDATE,
} from '../actions/reward';

export default {
  reward: handleActions({
    [combineActions(
      READ,
      UPDATE,
    )]: (reward, {
      payload,
    }) => payload,
  }, {}),
};
