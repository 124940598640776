import cookie from 'js-cookie';
import { AUTHORIZATION } from 'shared/constants/cookies';
import {
  LOGIN,
  LOGOUT,
} from 'shared/actions/auth';

export default () => next => (action) => {
  const { payload, type, meta } = action;
  if (type === LOGIN) {
    const { authorization } = payload;
    if (meta.shouldRemember) {
      cookie.set(AUTHORIZATION, authorization, { expires: 60 });
    } else {
      cookie.set(AUTHORIZATION, authorization);
    }
  }
  if (type === LOGOUT) {
    cookie.remove(AUTHORIZATION);
  }
  return next(action);
};
