import { createAction, actionTypeCreator, read, put, post } from 'relient/actions';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ALL = actionType('READ_ALL');
export const READ_ONE = actionType('READ_ONE');
export const UPDATE = actionType('UPDATE');
export const CREATE = actionType('CREATE');
export const READ_BY_CODE = actionType('READ_BY_CODE');

export const readAll = createAction(
  READ_ALL, ({
    page = 0,
    level,
    parentAgencyId,
    level1AgencyId,
    size = DEFAULT_SIZE,
  } = {}) => read('/agency/all', {
    page,
    level,
    parentAgencyId,
    level1AgencyId,
    size,
  }),
);

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/agency/${id}`),
);

export const update = createAction(
  UPDATE, ({
    id,
    name,
    description,
    status,
  }) => put(`/agency/${id}`, {
    id,
    name,
    description,
    status,
  }),
);

export const create = createAction(
  CREATE, ({
    name,
    description,
    status,
    level,
  }) => post('/agency', {
    name,
    description,
    status,
    level,
  }),
);

export const readByCode = createAction(
  READ_BY_CODE,
  ({ invitationCode }) => read('/agency/action/read-by-code', { invitationCode }),
);
