import { handleActions, combineActions } from 'relient/reducers';
import {
  READ_ONE,
  UPDATE,
} from '../actions/withdrawal-notification';

export default {
  withdrawalNotification: handleActions({
    [combineActions(
      READ_ONE,
      UPDATE,
    )]: (withdrawalNotification, {
      payload,
    }) => payload,
  }, {}),
};
