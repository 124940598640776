import { createAction, actionTypeCreator, read } from 'relient/actions';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ONE = actionType('READ_ONE');
export const READ_ALL = actionType('READ_ALL');
export const READ_MINE = actionType('READ_MINE');

export const readAll = createAction(
  READ_ALL,
  ({ page = 0, size = DEFAULT_SIZE } = {}) => read('/wallet/all', { page, size }),
);

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/wallet/${id}`),
);

export const readMine = createAction(
  READ_MINE,
  ({ page = 0, size = DEFAULT_SIZE } = {}) => read('/wallet/mine', { page, size }),
);
