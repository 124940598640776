import { createAction, actionTypeCreator, post, read, put, del } from 'relient/actions';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ALL = actionType('READ_ALL');
export const READ_ONE = actionType('READ_ONE');
export const CREATE = actionType('CREATE');
export const UPDATE = actionType('UPDATE');
export const REMOVE = actionType('REMOVE');

export const readOne = createAction(
  READ_ONE,
  ({ id }) => read(`/news/${id}`),
);

export const readAll = createAction(
  READ_ALL, ({
    language,
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/news/all', { page, size, sort, language }),
);

export const create = createAction(
  CREATE, ({
    title,
    content,
    author,
    thumbnails,
    status,
    language,
  }) => post('/news', {
    title,
    content,
    author,
    thumbnails,
    status,
    language,
  }),
);

export const update = createAction(
  UPDATE, ({
    id,
    title,
    content,
    author,
    thumbnails,
    status,
  }) => put(`/news/${id}`, {
    id,
    title,
    content,
    author,
    thumbnails,
    status,
  }),
);

export const remove = createAction(REMOVE, ({ id }) => del(`/news/${id}`));
