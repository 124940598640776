import { propertyOf } from 'lodash/fp';

export const CN = 'zh_CN';
export const EN = 'en_US';
export const JP = 'ja_JP';
export const KR = 'ko_KR';

export const languageOptions = [
  // { text: '简体中文', value: CN },
  { text: 'English', value: EN },
  { text: '日本語', value: JP },
  // { text: '한국어', value: KR },
];

export const getLanguageText = propertyOf({
  [CN]: '简体中文',
  [EN]: 'English',
  [JP]: '日本語',
  [KR]: '한국어',
});
