import { handleActions } from 'relient/reducers';
import {
  SET_FEATURE,
  SET_FEATURE_GROUP,
  SET_USER_AGENT,
  TOGGLE_LOGIN_MODAL,
  TOGGLE_REGISTER_MODAL,
  TOGGLE_FORGET_PASSWORD_MODAL,
  TOGGLE_RESET_PASSWORD_MODAL,
  SET_HOST,
} from 'shared/actions/global';

export default {
  global: handleActions({
    [SET_FEATURE]: (global, { payload }) => ({
      ...global,
      feature: payload,
    }),

    [SET_FEATURE_GROUP]: (global, { payload }) => ({
      ...global,
      featureGroup: payload,
    }),

    [SET_USER_AGENT]: (global, { payload }) => ({
      ...global,
      userAgent: payload,
    }),

    [TOGGLE_LOGIN_MODAL]: global => ({
      ...global,
      isLoginModalOpen: !global.isLoginModalOpen,
    }),

    [TOGGLE_REGISTER_MODAL]: global => ({
      ...global,
      isRegisterModalOpen: !global.isRegisterModalOpen,
    }),

    [TOGGLE_FORGET_PASSWORD_MODAL]: global => ({
      ...global,
      isForgetPasswordModalOpen: !global.isForgetPasswordModalOpen,
    }),

    [TOGGLE_RESET_PASSWORD_MODAL]: global => ({
      ...global,
      isResetPasswordModalOpen: !global.isResetPasswordModalOpen,
    }),

    [SET_HOST]: (global, { payload }) => ({
      ...global,
      host: payload,
    }),
  }, {
    isLoginModalOpen: false,
    isRegisterModalOpen: false,
    isForgetPasswordModalOpen: false,
    isResetPasswordModalOpen: false,
    feature: null,
    featureGroup: null,
    userAgent: null,
    host: null,
  }),
};
