import { merge, remove, handleActions, combineActions } from 'relient/reducers';
import { news } from '../schema';
import {
  UPDATE,
  READ_ALL,
  CREATE,
  REMOVE,
  READ_ONE,
} from '../actions/news';

export default {
  news: handleActions({
    [combineActions(
      UPDATE,
      CREATE,
      READ_ONE,
    )]: merge({ schema: news }),

    [combineActions(READ_ALL)]: merge({ schema: news, dataKey: 'content' }),

    [REMOVE]: remove(news),

  }, {}),
};
