import { createAction, actionTypeCreator, read } from 'relient/actions';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ALL_RECHARGE = actionType('READ_ALL_RECHARGE');
export const READ_ALL_WITHDRAWAL = actionType('READ_ALL_WITHDRAWAL');
export const READ_ALL_APPLICATION = actionType('READ_ALL_APPLICATION');

export const readAllRecharge = createAction(
  READ_ALL_RECHARGE,
  ({
    applicationId,
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read(`/third-party-application/${applicationId}/platform/recharge/all`, {
    page,
    size,
    sort,
  }),
);

export const readAllWithdrawal = createAction(
  READ_ALL_WITHDRAWAL,
  ({
    applicationId,
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read(`/third-party-application/${applicationId}/platform/withdrawal/all`, {
    page,
    size,
    sort,
  }),
);

export const readAllApplication = createAction(
  READ_ALL_APPLICATION,
  ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/third-party-application/all', {
    page,
    size,
    sort,
  }),
);
