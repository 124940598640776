import { createAction, actionTypeCreator, read } from 'relient/actions';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_MINE = actionType('READ_MINE');
export const READ_ALL = actionType('READ_ALL');

export const readMine = createAction(
  READ_MINE, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/earning/mine', { page, size, sort }),
);

export const readAll = createAction(
  READ_ALL, ({
    page = 0,
    size = DEFAULT_SIZE,
    sort = 'createdAt,desc',
  } = {}) => read('/earning/all', { page, size, sort }),
);
