import { post, actionTypeCreator, createAction } from 'relient/actions';

const actionType = actionTypeCreator(__filename);

export const LOGIN = actionType('LOGIN');
export const login = createAction(
  LOGIN,
  ({ username, password, captcha, type }) => post(
    `/auth/local?type=${type}`,
    { username, password, captcha, type },
    { headers: { 'x-auth-username': username, 'x-auth-password': password } }),
  ({ shouldRemember }) => ({ ignoreAuthRedirection: true, shouldRemember }),
);

export const LOGOUT = actionType('LOGOUT');
export const logout = createAction(LOGOUT);

export const SET_AUTHORIZATION = actionType('SET_AUTHORIZATION');
export const setAuthorization = createAction(SET_AUTHORIZATION);

export const REMOVE_AUTHORIZATION = actionType('REMOVE_AUTHORIZATION');
export const removeAuthorization = createAction(REMOVE_AUTHORIZATION);
