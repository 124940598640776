import { handleActions, combineActions } from 'relient/reducers';
import {
  READ_ONE,
  UPDATE,
} from '../actions/withdrawal-processing-fee';

export default {
  withdrawalProcessingFee: handleActions({
    [combineActions(
      READ_ONE,
      UPDATE,
    )]: (withdrawalProcessingFee, {
      payload,
    }) => payload,
  }, 0),
};
