import { createAction, actionTypeCreator, read as readAction, put } from 'relient/actions';

const actionType = actionTypeCreator(__filename);

export const READ = actionType('READ');
export const UPDATE = actionType('UPDATE');

export const read = createAction(
  READ,
  () => readAction('/system-setting/reward'),
);

export const update = createAction(
  UPDATE, ({
    primary,
    secondary,
  }) => put('/system-setting/reward', {
    primary,
    secondary,
  }),
);
