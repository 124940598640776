import { createAction, actionTypeCreator, read, put } from 'relient/actions';
import { toNumber } from 'lodash/fp';
import { DEFAULT_SIZE } from 'shared/constants/pagination';

const actionType = actionTypeCreator(__filename);

export const READ_ALL = actionType('READ_ALL');
export const UPDATE = actionType('UPDATE');

export const readAll = createAction(
  READ_ALL,
  ({ page = 0, size = DEFAULT_SIZE } = {}) => read('/agency-contract/all', { page, size }),
);

export const update = createAction(
  UPDATE, ({
    id,
    platformFeeRate,
    agencyFeeRate,
    salesCommissionRate,
  }) => put(`/agency-contract/${id}`, {
    id,
    platformFeeRate: toNumber(platformFeeRate),
    agencyFeeRate: toNumber(agencyFeeRate),
    salesCommissionRate: toNumber(salesCommissionRate),
  }),
);
