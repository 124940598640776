import { keys } from 'lodash/fp';
import getText from 'relient/get-text';
import getOptions from 'relient/get-options';

export const ALIPAY = 'ALIPAY';
export const PAYPAL = 'PAYPAL';
export const CASH = 'CASH';
export const BTC = 'BTC';
export const BCH = 'BCH';
export const THIRD_PARTY = 'THIRD_PARTY';
export const REWARD = 'REWARD';
export const WECHAT_PAY_OFFLINE = 'WECHAT_PAY_OFFLINE';
export const WECHAT_PAY = 'WECHAT_PAY';
export const ALIPAY_NATIVE = 'ALIPAY_NATIVE';
export const ALIPAY_OFFLINE = 'ALIPAY_OFFLINE';
export const WECHAT_PAY_NATIVE = 'WECHAT_PAY_NATIVE';

const textMap = {
  [ALIPAY]: 'alipay',
  [PAYPAL]: 'paypal',
  [CASH]: 'cash',
  [BTC]: 'BTC',
  [BCH]: 'BCH',
  [THIRD_PARTY]: 'thirdPartyPayment',
  [REWARD]: 'reward',
  [WECHAT_PAY_OFFLINE]: 'wechatPayOffline',
  [WECHAT_PAY]: 'wechatPay',
  [ALIPAY_NATIVE]: 'alipayNative',
  [ALIPAY_OFFLINE]: 'alipayOffline',
  [WECHAT_PAY_NATIVE]: 'wechatPayNative',
};

export const paymentMethods = keys(textMap);
export const getPaymentMethodText = getText(textMap);
export const getPaymentMethodOptions = getOptions(textMap);
