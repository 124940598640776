import { propEq, find, flow, prop } from 'lodash/fp';

export const GLOBAL = 'GLOBAL';

const featureGroups = [{
  name: GLOBAL,
  link: '',
  text: '',
}];

export const getFeatureGroupBy = attribute => name => flow(
  find(propEq('name', name)),
  prop(attribute),
)(featureGroups);
