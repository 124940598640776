import {
  GLOBAL,
} from 'shared/constants/feature-groups';

export default [
  {
    path: '/product-center',
    chunks: ['product-center'],
    featureGroup: GLOBAL,
    children: true,
    load: () => import(/* webpackChunkName: 'product-center' */ 'modules/product-center'),
  },

  {
    path: '/financial-center',
    chunks: ['financial-center'],
    featureGroup: GLOBAL,
    children: true,
    load: () => import(/* webpackChunkName: 'financial-center' */ 'modules/financial-center'),
  },

  {
    path: '/wallet',
    chunks: ['wallet'],
    featureGroup: GLOBAL,
    children: true,
    load: () => import(/* webpackChunkName: 'wallet' */ 'modules/wallet'),
  },

  {
    path: '/',
    chunks: ['home'],
    featureGroup: GLOBAL,
    children: true,
    load: () => import(/* webpackChunkName: 'home' */ 'modules/home'),
  },

  {
    path: '/(.*)',
    chunks: ['not-found'],
    featureGroup: 'NOT_FOUND',
    load: () => import(/* webpackChunkName: 'not-found' */ 'modules/not-found'),
  },
];
