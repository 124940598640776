import { merge, handleActions } from 'relient/reducers';
import { accountPayment } from '../schema';
import {
  CREATE,
  READ_BY_AGENCY,
} from '../actions/agency-payment';

export default {
  agencyPayment: handleActions({
    [CREATE]: merge({ schema: accountPayment }),

    [READ_BY_AGENCY]: merge({ schema: accountPayment }),

  }, {}),
};
