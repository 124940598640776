import { handleActions, merge } from 'relient/reducers';
import {
  READ_ALL_WITHDRAWAL,
} from '../actions/third-party';
import { thirdPartyWithdrawal } from '../schema';

export default {
  thirdPartyWithdrawal: handleActions({
    [READ_ALL_WITHDRAWAL]: merge({ schema: thirdPartyWithdrawal, dataKey: 'content' }),

  }, {}),
};
