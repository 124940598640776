import { handleActions, merge } from 'relient/reducers';
import {
  READ_ALL_RECHARGE,
} from '../actions/third-party';
import { thirdPartyRecharge } from '../schema';

export default {
  thirdPartyRecharge: handleActions({
    [READ_ALL_RECHARGE]: merge({ schema: thirdPartyRecharge, dataKey: 'content' }),

  }, {}),
};
