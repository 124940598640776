import { prop, startsWith, forEach } from 'lodash/fp';
import {
  GLOBAL,
  getFeatureGroupBy,
} from './feature-groups';

export const features = {};

const createFeature = forEach(({ groupName, items }) => {
  forEach(({ name, link, text, icon }) => {
    features[name] = { name, link, text, icon, groupName };
  })(items);
});

export const HOME = 'HOME';
export const PRODUCT_CENTER = 'PRODUCT_CENTER';
export const EXCHANGE = 'EXCHANGE';
export const FINANCIAL_CENTER = 'FINANCIAL_CENTER';
export const WALLET = 'WALLET';

createFeature([{
  groupName: GLOBAL,
  items: [{
    name: HOME,
    link: '',
    text: 'home',
    icon: 'home',
  }, {
    name: PRODUCT_CENTER,
    link: 'product-center',
    text: 'productCenter',
    icon: 'product-center',
  }, {
    name: FINANCIAL_CENTER,
    link: 'financial-center',
    text: 'financialCenter',
    icon: 'financial-center',
  }, {
    name: WALLET,
    link: 'wallet',
    text: 'wallet',
    icon: 'wallet',
  }],
}]);

export const getFeatureBy = attribute => (name) => {
  if (attribute === 'link') {
    const { link, groupName } = features[name];
    if (!startsWith('/')(link)) {
      const groupLink = getFeatureGroupBy('link')(groupName);
      if (groupLink) {
        return `/${groupLink}/${link}`;
      }
      return `/${link}`;
    }
    return link;
  }
  return prop(`${name}.${attribute}`)(features);
};
