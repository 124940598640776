import { handleActions, merge } from 'relient/reducers';
import {
  READ_ALL_APPLICATION,
} from '../actions/third-party';
import { thirdPartyApplication } from '../schema';

export default {
  thirdPartyApplication: handleActions({
    [READ_ALL_APPLICATION]: merge({ schema: thirdPartyApplication, dataKey: 'content' }),

  }, {}),
};
