import { createAction, actionTypeCreator, read, post } from 'relient/actions';
import { toNumber } from 'lodash/fp';

const actionType = actionTypeCreator(__filename);

export const READ_ALL = actionType('READ_ALL');
export const CREATE = actionType('CREATE');

export const readAll = createAction(
  READ_ALL,
  ({ type }) => read('/contract/withdrawal/all', { type }),
);

export const create = createAction(
  CREATE,
  ({
    amount,
    type,
    issuedAt,
    cryptocurrencyId,
  }) => post('/contract/action/withdraw', {
    amount: toNumber(amount),
    type,
    issuedAt,
    cryptocurrencyId,
  }),
);
