import { createAction, actionTypeCreator, read } from 'relient/actions';

const actionType = actionTypeCreator(__filename);

export const READ_ALL = actionType('READ_ALL');

export const readAll = createAction(
  READ_ALL,
  () => read('/role/all'),
);
