import { merge, handleActions, combineActions } from 'relient/reducers';
import { agency } from '../schema';
import {
  UPDATE,
  READ_ALL,
  CREATE,
  READ_BY_CODE,
  READ_ONE,
} from '../actions/agency';

export default {
  agency: handleActions({
    [combineActions(
      UPDATE,
      CREATE,
      READ_BY_CODE,
      READ_ONE,
    )]: merge({ schema: agency }),

    [READ_ALL]: merge({ schema: agency, dataKey: 'content' }),

  }, {}),
};
