import { createContext } from 'react';
import { fromRenderProps } from 'recompose';
import { identity } from 'lodash/fp';
import { price } from 'relient/formatters';
import { EN } from 'shared/constants/languages';

export const DomainContext = createContext({ apiDomain: '', cdnDomain: '' });

export const withDomainContext = fromRenderProps(DomainContext.Consumer, identity);

export const I18NContext = createContext(null);

export const withI18N = fromRenderProps(I18NContext.Consumer, ({ i18n, language }) => ({
  i18n,
  language,
  price: (dollar) => {
    if (language === EN) {
      return price({ currency: '$' })(dollar);
    }
    return price({ currency: '¥' })(dollar * global.exchangeRate.USDtoJPY);
  },
}));
