import { merge, handleActions } from 'relient/reducers';
import { log } from '../schema';
import {
  READ_ALL,
} from '../actions/log';

export default {
  log: handleActions({
    [READ_ALL]: merge({ schema: log, dataKey: 'content' }),

  }, {}),
};
