import { merge, remove, handleActions, combineActions } from 'relient/reducers';
import { coupon } from '../schema';
import {
  CREATE,
  UPDATE,
  READ_ALL,
  REMOVE,
} from '../actions/coupon';

export default {
  coupon: handleActions({
    [READ_ALL]: merge({ schema: coupon, dataKey: 'content' }),

    [combineActions(
      CREATE,
      UPDATE,
    )]: merge({ schema: coupon }),

    [REMOVE]: remove(coupon),

  }, {}),
};
